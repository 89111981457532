.navLinks {
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important ;
    font-weight: 600 !important ;
    word-spacing: 2px !important;
    padding: 1% 3% !important;
    margin: 0 0 0 2% !important;
    color: #000 !important;
}

.navLinks:hover {
    color: #81450A !important;
    background-color: #FFF !important;
}

.listItemsText {
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    word-spacing: 2px;
    text-transform: capitalize;
}

.listItemsText:hover {
    color: #FFF;
}


.navItems {
    text-transform: capitalize !important;
    font-family: 'Ubuntu', sans-serif !important ;
    font-size: 16px !important ;
    font-weight: 600 !important ;
    word-spacing: 2px !important;
    padding: 2% !important;
    margin: 0 3% !important;
    color: #000 !important;
}

.navItems:hover {
    color: #81450A !important;
}